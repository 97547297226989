import React from "react";
import "./Testimonial.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

import { Pagination } from "swiper";
import "swiper/css/pagination";
import profilePic1 from "../../img/profile1.jpg";
import profilePic2 from "../../img/profile2.jpg";
import profilePic3 from "../../img/profile3.jpg";
import profilePic4 from "../../img/profile4.jpg";

const Testimonial = () => {
  const clients = [
    {
      img: profilePic1,
      review:
        "We are extremely thrilled with the exceptional product/service quality provided by Ebam Info. Thanks to their offerings, we have been able to achieve an outstanding increase in productivity and efficiency of more than 42%. We highly commend the entire team for their remarkable work! Keep up the excellent job! Ebam Customer !",
    },
    {
      img: profilePic2,
      review:
        "Words cannot express how delighted we are with Ebam Info's product/service quality. The level of attention to detail and precision is remarkable. Our team has experienced a significant boost in productivity and effectiveness since implementing your solutions. Kudos to the entire Ebam Info team! Building Doctor Chennai !",
    },
    {
      img: profilePic3,
      review:
        "We are extremely impressed with the exceptional quality of the products/services provided by Ebam Info. The impact on our business has been phenomenal, with improved efficiency and cost savings. It's evident that your team's expertise and dedication are second to none. Thank you for delivering excellence! SVE Pharma !",
    },
    {
      img: profilePic4,
      review:
        "We are overjoyed with the impeccable product/service quality delivered by Ebam Info. Our organization has experienced a remarkable transformation, witnessing a substantial increase in productivity and a reduction in errors. Your team's dedication to excellence is commendable and greatly appreciated ! Ebam Customer !",
    },
  ];

  return (
    <div className="t-wrapper" id="testimonial">
      <div className="t-heading">
        <span>Clients always get </span>
        <span>Exceptional Work </span>
        <span>from us...</span>
      <div className="blur t-blur1" style={{ background: "var(--purple)" }}></div>
      <div className="blur t-blur2" style={{ background: "skyblue" }}></div>

      </div>
      <Swiper
        // install Swiper modules
        modules={[Pagination]}
        slidesPerView={1}
        pagination={{ clickable: true }}
      >
        {clients.map((client, index) => {
          return (
            <SwiperSlide key={index}>
              <div className="testimonial">
                <img src={client.img} alt="" />
                <span style={{fontSize:'16px'}}>{client.review}</span>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default Testimonial;

import React, { useContext } from "react";
import "./Intro.css";
import Vector1 from "../../img/Vector1.png";
import Vector2 from "../../img/Vector2.png";
import boy from "../../img/boy.png";
import WebIcon from "../../img/webdevelopment.png";
import MobIcon from "../../img/mobiledevelopment.png";
import Aicon from "../../img/aidevelopment.png";
import EnterpriseIcon from "../../img/EnterpriseDevelopment.png";
import CustomIcon from "../../img/CustomizedApp.png";
import FloatinDiv from "../FloatingDiv/FloatingDiv";
import Twitter from "../../img/Twitter.png";
import Facebook from "../../img/Facebook.png";
import LinkedIn from "../../img/linkedin.png";
import Instagram from "../../img/instagram.png";
import { themeContext } from "../../Context";
import { motion } from "framer-motion";
import { Link } from "react-scroll";
import { SocialMediaLinks } from "../Constants/ebam.constants";
const Intro = () => {
  // Transition
  const transition = { duration: 2, type: "spring" };

  // context
  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;

  return (
    <div className="Intro" id="Intro">
      {/* left name side */}
      <div className="i-left">
        <div className="i-name">
          {/* Intro title */}
          <span style={{ color: darkMode ? "white" : "" }}>Hy! We are</span>
          <span>Ebam Info Solutions</span>
          <span>Welcome to Ebam Info Solutions! your trusted partner for innovative software solutions!</span>
          <span>We are a dynamic and customer-focused software development company dedicated to delivering high-quality and cutting-edge software products.At Ebam Info Solutions, we understand the importance of technology in driving business growth and efficiency.</span>
        </div>
        <Link to="contact" smooth={true} spy={true}>
          <button className="button i-button">Touch with us !</button>
        </Link>
        {/* social icons */}
        <div className="i-icons">
          <a href={SocialMediaLinks.fb}>
          <img src={Facebook} alt="" />
          </a>
          <a href={SocialMediaLinks.linkedIn}>
          <img src={LinkedIn} alt="" />
          </a>
          <a href={SocialMediaLinks.insta}>
          <img src={Instagram} alt="" />
          </a>
          <a href={SocialMediaLinks.twitter}>
          <img src={Twitter} alt="" />
          </a>
        </div>
      </div>
      {/* right image side */}
      <div className="i-right">
        <img src={Vector1} alt="" />
        <img src={Vector2} alt="" />
        <img src={boy} alt="" />

        <motion.div
          initial={{ top: "-8%", left: "74%" }}
          whileInView={{ left: "65%" }}
          transition={transition}
          className="floating-div"
        >
        <FloatinDiv img={WebIcon} text1="Web" text2="Development" />
        </motion.div>

        <motion.div
          initial={{ top: "12%", left: "74%" }}
          whileInView={{ left: "65%" }}
          transition={transition}
          className="floating-div"
        >
        <FloatinDiv img={MobIcon} text1="Fintech" text2="Development" />
        </motion.div>

        <motion.div
          initial={{ top: "32%",left: "74%" }}
          whileInView={{ left: "65%" }}
          transition={transition}
          className="floating-div"
        >
          <FloatinDiv img={EnterpriseIcon} text1="Enterprise" text2="Development" />
        </motion.div>

        <motion.div
          initial={{ top: "52%",left: "74%" }}
          whileInView={{ left: "65%" }}
          transition={transition}
          className="floating-div"
        >
          <FloatinDiv img={CustomIcon} text1="Customized App" text2="Development" />
        </motion.div>

        <motion.div
          initial={{ top: "72%",left: "74%" }}
          whileInView={{ left: "65%" }}
          transition={transition}
          className="floating-div"
        >
          <FloatinDiv img={Aicon} text1="AI & Cloud" text2="Development" />
        </motion.div>

        <div className="blur" style={{ background: "rgb(238 210 255)" }}></div>
        <div
          className="blur"
          style={{
            background: "#C1F5FF",
            top: "17rem",
            width: "21rem",
            height: "11rem",
            left: "-9rem",
          }}
        ></div>
      </div>
    </div>
  );
};

export default Intro;

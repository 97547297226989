import React, { useContext, useRef, useState } from "react";
import "./Address.css";
import { themeContext } from "../../Context";
import { motion } from "framer-motion";
const Address = () => {
  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;
  const form = useRef();
  const [showAddress, setShowAddress] = useState(false);

  const handleMouseEnter = () => {
    setShowAddress(true);
  };

  const handleMouseLeave = () => {
    setShowAddress(false);
  };


  return (
    <div className="contact-form" id="contact">
      {/* left side copy and paste from work section */}
      <div className="w-left">
        <div className="awesome">
          {/* darkMode */}
          <span style={{color: darkMode?'white': ''}}>Reach out us !</span>
          <span>Corporate Address</span>
          <div
            className="blur s-blur1"
            style={{ background: "#ABF1FF94" }}
          ></div>
        </div>
      </div>
      {/* right side form */}
      <div className="c-right">
      <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ cursor: "pointer" }}
    >
      <h3 style={{ marginBottom: "10px", color: darkMode ? "white" : "orange" }}>Show me address</h3>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: showAddress ? 1 : 0, y: showAddress ? 0 : 20 }}
        transition={{ duration: 0.3 }}
        style={{
          background: "#f2f2f2",
          color:"black",
          padding: "10px",
          borderRadius: "5px",
          marginTop: "10px",
        }}
      >
        <p>NO 05 , MANSAA HOUSE</p>
        <p>ALS NAGAR FIRST MAIN STREET</p>
        <p>MADAMBAKKAM</p>
        <p>CHENNAI - 600126</p>
        <p>TAMIL NADU</p>
        <p>INDIA</p>
      </motion.div>
    </div>
      </div>
    </div>
    
  );
};

export default Address;

import React, { useContext } from "react";
import Toggle from "../Toggle/Toggle";
import "./Navbar.css";
import { Link } from "react-scroll";
import EbamLogo from "../../img/Ebamlogo.png";
import Ebamlogowhite from "../../img/Ebamlogowhite.png";
import { themeContext } from "../../Context";

const Navbar = () => {
  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;

  return (
    <div className="n-wrapper" id="Navbar">
      {/* left */}
      <div className="n-left">
        {darkMode ? (
          <img src={Ebamlogowhite} alt="Ebam Logo" className="n-name" />
        ) : (
          <img src={EbamLogo} alt="Ebam Logo" className="n-name" />
        )}
        <Toggle />
      </div>
      {/* right */}
      <div className="n-right">
        <div className="n-list">
          <ul style={{ listStyleType: "none" }}>
            <li>
              <Link activeClass="active" to="Navbar" spy={true} smooth={true}>
                Home
              </Link>
            </li>
            <li>
              <Link to="services" spy={true} smooth={true}>
                Services
              </Link>
            </li>
            <li>
              <Link to="fintech" spy={true} smooth={true}>
                FinTech
              </Link>
            </li>
            <li>
              <Link to="works" spy={true} smooth={true}>
                Experience
              </Link>
            </li>
            <li>
              <Link to="portfolio" spy={true} smooth={true}>
                Products
              </Link>
            </li>
            <li>
              <Link to="testimonial" spy={true} smooth={true}>
                Testimonial
              </Link>
            </li>
            <li>
              <Link to="contact" spy={true} smooth={true}>
              <button className="button n-button" style={{marginTop:'-6px'}}>Contact</button>
              </Link>
            </li>
          </ul>
          
        </div>
        
      </div>
    </div>
  );
};

export default Navbar;

import React, { useContext, useRef, useState } from "react";
import "./Contact.css";
import emailjs from "@emailjs/browser";
import { themeContext } from "../../Context";
import { motion, AnimatePresence } from "framer-motion";

const Contact = () => {
  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;
  const form = useRef();
  const [done, setDone] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [errors, setErrors] = useState({
    user_name: false,
    user_email: false,
    message: false,
  });

  const validateForm = () => {
    const errorsCopy = { ...errors };
    let isValid = true;

    if (!form.current.user_name.value.trim()) {
      errorsCopy.user_name = "Name is required";
      isValid = false;
    }
    console.log("errors.user_name >",errors.user_name)

    if (!/^\S+@\S+\.\S+$/.test(form.current.user_email.value)) {
      errorsCopy.user_email = "Please enter your valid email address";
      isValid = false;
    }
    console.log("errors.user_email >",errors.user_email)

    if (!form.current.message.value.trim()) {
      errorsCopy.message = "Message is required";
      isValid = false;
    }
    console.log("errors.message >",errors.message)

    setErrors(errorsCopy);
    return isValid;
  };

  const sendEmail = (e) => {
    e.preventDefault();

    if (validateForm()) {
      emailjs
        .sendForm(
          "service_affcdlw",
          "template_r1roh07",
          form.current,
          "OFdNPvRUX5yx-WqwQ"
        )
        .then(
          (result) => {
            console.log(result.text);
            setDone(true);
            form.current.reset();
            setShowSnackbar(true);
            setTimeout(() => {
              setShowSnackbar(false);
            }, 3000);
          },
          (error) => {
            console.log(error.text);
          }
        );
    }
  };

  return (
    <div className="contact-form" id="contact">
      {/* left side copy and paste from work section */}
      <div className="w-left">
        <div className="awesome">
          {/* darkMode */}
          <span style={{ color: darkMode ? "white" : "" }}>Get in Touch</span>
          <span>Contact us</span>
          <div className="blur s-blur1" style={{ background: "#ABF1FF94" }}></div>
        </div>
      </div>
      {/* right side form */}
      <div className="c-right">
        <form ref={form} onSubmit={sendEmail}>
          <input
            type="text"
            name="user_name"
            className={`user ${errors.user_name ? "error" : ""}`}
            placeholder={errors.user_name || "Name"}
          />
          <input
            type="email"
            name="user_email"
            className={`user ${errors.user_email ? "error" : ""}`}
            placeholder={errors.user_email || "Email"}
          />
          <textarea
            name="message"
            className={`user ${errors.message ? "error" : ""}`}
            placeholder={errors.message || "Message"}
          />
          <input type="submit" value="Send" className="button" />
          <span></span>
          <AnimatePresence>
            {showSnackbar && (
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 50 }}
                style={{
                  position: "sticky",
                  bottom: 20,
                  left: "50%",
                  transform: "translateX(-50%)",
                  padding: 16,
                  borderRadius: 8,
                  background: "green",
                  color: "white",
                  zIndex: 999,
                  fontSize: "16px",
                }}
              >
                {done && "Email send success! please keep an eye on your email box!"}
              </motion.div>
            )}
          </AnimatePresence>
          <div className="blur c-blur1" style={{ background: "var(--purple)" }}></div>
        </form>
      </div>
    </div>
  );
};

export default Contact;
